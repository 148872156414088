<template>
  <a-card :bordered="false">
    <div>
      <a-form :form="form" >
        <a-form-item label="编号" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['number']" disabled/>
        </a-form-item>
        <a-form-item label="名称" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input v-decorator="['name', {rules: [{required: true, message: '必填项，请填写信息'}, {max:32, message: '输入超出长度限制'}]}]" />
        </a-form-item>
        <a-form-item label="类型" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-select
            v-decorator="[
              'type',
              {
                rules: [
                  { required: true, message: '请选择' }
                ],
              },
            ]"
            placeholder="选择"
            show-search
            option-filter-prop="children"
            :filter-option="filterOption">
            <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.trade_material_type">{{ val }}</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="已存档" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-switch v-decorator="['is_archived', { valuePropName: 'checked' }]" checked-children="是" un-checked-children="否"/>
        </a-form-item>
        <a-form-item label="备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-textarea :rows="4" v-decorator="['remark', {rules: [{required: false, message: '必填项，请填写信息'}]}]" />
        </a-form-item>
        <a-form-item v-bind="buttonCol">
          <a-row>
            <a-col span="6">
              <a-button type="primary" html-type="submit" @click="handleSubmit">提交</a-button>
            </a-col>
            <a-col span="10">
              <a-button @click="handleGoBack">返回</a-button>
            </a-col>
            <a-col span="8"></a-col>
          </a-row>
        </a-form-item>
      </a-form>
    </div>
  </a-card>
</template>

<script>
import moment from 'moment'
import pick from 'lodash.pick'
import { project_material_update, project_material_list } from '@/api/trade_project_material'
const fields = ['id', 'number', 'name', 'type', 'is_archived', 'remark']
export default {
  name: 'TableEdit',
  props: {
    record: {
      type: [Object, String],
      default: ''
    }
  },
  data () {
    return {
      labelCol: {
        xs: { span: 6 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 }
      },
      buttonCol: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 12, offset: 5 }
        }
      },
      form: this.$form.createForm(this),
      id: 0,
      data: {}
    }
  },
  mounted () {
    console.log('-------------------', this.$route.params.material_id)
    project_material_list({ id: this.$route.params.material_id })
      .then(res => {
        console.log(res.data, '我是数据列表@')
        const list = res.data.entries
        if (list && list.length > 0) {
          this.data = list[0]
          this.data.type = '' + this.data.type
          this.id = this.data.id
          this.loadEditInfo(this.data)
        }
      })
  },
  methods: {
    moment,
    handleGoBack () {
      // this.$emit('onGoBack')
      this.$router.go(-1)
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        console.log('我要提交接口', values)
        if (!err) {
          project_material_update(values, this.id)
            .then((res) => {
              if (res.code === 1000) {
                this.handleGoBack()
                console.log(res, '修改成功')
              }
            })
        }
      })
    },
    loadEditInfo (data) {
      const { form } = this
      console.log(`将加载 ${this} 信息到表单`)
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        const formData = pick(data, fields)
        console.log('formData', formData)
        this.id = formData.id
        formData.type = String(formData.type)
        form.setFieldsValue(formData)
      })
    }
  }
}
</script>
